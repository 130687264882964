.footer {
    background-color: #222222;

    h3 {
        color: #ddd;
        margin: 14px 0;
    }

    a {
        color: #bbb;

        &:hover {
            color: #fff;
        }
    }

    .wx-area {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: #bbb;
        position: fixed;
        right: 100px;
        bottom: 90px;
        text-align: center;
        font-size: 26px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            .wx-img {
                width: 100px;
                height: 100px;
                bottom: -50%;
            }
        }

        .wx-img {
            transition: all .7s linear;
            width: 0px;
            height: 0px;
            position: absolute;
            right: 40px;
            bottom: 20px;
        }
    }

    .footer-top {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px 14px;

        .footer-about,
        .footer-produuct,
        .footer-contact,
        .footer-new-media {
            >div {
                color: #bbb;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        .footer-about,
        .footer-produuct {
            >div {
                cursor: pointer;
            }
        }

        .footer-new-media {
            .qr-code {
                text-align: center;

                >div {
                    padding-bottom: 4px;
                }

                >img {
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }

    .footer-copyright {
        background-color: #191919;
        min-height: 50px;
        line-height: 50px;
        text-align: center;
        color: #bbb;
        font-size: 14px;

        .icp {
            margin-left: 4px;
        }
    }

    @media screen and (max-width: 1200px) {
        .footer-top {
            width: 100%;
            display: block;

            >div {
                text-align: center;
            }
        }

        .footer-copyright {
            min-height: 46px;
            line-height: 46px;
        }

        .wx-area {
            right: 60px;

            .wx-img {
                top: -25px;
            }
        }
    }

    @media screen and (min-width:768px) and (max-width: 1200px) {
        .wx-area {
            right: 100px;
        }
    }
}